import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '*', component: () => import('../pages/404')},
        {
            path: '/', component: () => import('../pages/Base'), children: [
                {path: '', component: () => import('@/pages/Home')},
                {path: 'account', component: () => import('@/pages/Account')},
                {path: 'platform', component: () => import('@/pages/Platform')},
                {path: 'phone', component: () => import('@/pages/Phone')},
            ]
        },


    ]
})

router.beforeResolve((to, from, next) => {
    // console.log(from, to)
    next()
})

export default router
