import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/plugins/router'
import axios from '@/plugins/axios'
import store from '@/plugins/store'

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    axios,
    store: store,
    render: h => h(App),
}).$mount('#app')
