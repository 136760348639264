import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import zhHans from 'vuetify/lib/locale/zh-Hans'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify)

const opts = {
    lang: {
        locales: {zhHans, en},
        current: 'zhHans',
    },
}

export default new Vuetify(opts)