<template>
  <v-app>
    <router-view></router-view>
    <v-snackbar v-model="$store.state.snackbar.show" timeout="3000">
      {{ $store.state.snackbar.message }}
    </v-snackbar>
  </v-app>

</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>

</style>
