import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        system: {
            title: '',
            platforms: []
        },
        accountStatusList: [
            {value: 0, text: '状态0'},
            {value: 1, text: '状态1'},
        ],
        snackbar: {show: false, message: null},
        dialog: {show: false, message: null},
        member: JSON.parse(localStorage.getItem('m')) ? JSON.parse(localStorage.getItem('m')) : {}
    },
    mutations: {
        toast(state, message) {
            state.snackbar = {show: true, message: message}
        },
        init(state, system) {
            document.title = system.title
            state.system = system
        },
        showDialog(state, message) {
            state.dialog = {show: true, message: message}
        },
        closeDialog(state) {
            state.dialog = {show: false, message: null}
        },
        saveMember(state, member) {
            state.member = Object.assign({}, state.member, member)
            localStorage.setItem('m', JSON.stringify(state.member))
        },
        clearMember(state) {
            state.member = {}
            localStorage.setItem('m', JSON.stringify(state.member))
        }
    },
    actions: {},
    getters: {

        isLogin: state => {
            return Object.keys(state.member).length > 0
        },
        nickname: state => {
            if (!state.member) return null
            return state.member.nickname
        },
        points: state => {
            if (!state.member) return null
            return state.member.points
        },
    },
});


export default store