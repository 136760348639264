import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from "@/plugins/store";

axios.defaults.baseURL = 'https://aa.kingyang.net'
// axios.defaults.withCredentials = true
axios.interceptors.request.use(config => {
    config.headers.Token = localStorage.getItem('token')
    return config
})
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            store.commit('toast', error.response.data.message)
        } else {
            store.commit('toast', '网络错误，请检查网络连接。')
        }
        return Promise.reject(error.response)
    }
)
Vue.use(VueAxios, axios)

export default axios